import geocode from "./geocode";
import birthMonth from "./birthMonth";
import createTag from "./createTag";
import listingContacts from "./listingContacts";
import listingAccountOverWrites from "./listingAccountOverWrites";
import inquiryDetails from "./inquiryDetails";
import referralDetails from "./referralDetails";
import setPrimary from "./setPrimary";
import isUniqueEmail from "./validation/isUniqueEmail";
import eventCategory from "./eventCategory";
import accountListings from "./accountListings";
import setDefaultVenueAccount from "./setDefaultVenueAccount";
import setDefaultVenueListing from "./setDefaultVenueListing";
import accountContacts from "./accountContacts";
import listingSubcategories from "./listingSubcategories";
import connectionModules from "./connectionModules";
import exampleCallbackFile from "./exampleCallbackFile";
import getAssetIds from "./getAssetIds";
import moduleSettingGroupFilter from "./moduleSettingGroupFilter";
import submit from "./submit";
import cancel from "./cancel";
import handleApproval from "./handleApproval";
import handleApprovalAndEdit from "./handleApprovalAndEdit";
import handleDenial from "./handleDenial";
import handleUpdateSettings from "./handleUpdateSettings";
import setFieldPropsWithValue from "./setFieldPropsWithValue";
import contactsFromAccount from "./contactsFromAccount";
import setAccountContactRelationFields from "./setAccountContactRelationFields";
import optionLoader from "./optionLoader";
import fetchReferenceOptions from "./fetchReferenceOptions";
import dynamicStringSplit from "./dynamicStringSplit";
import dynamicStringJoin from "./dynamicStringJoin";
import uploadAssets from "./uploadAssets";
import uploadImages from "./uploadImages";
import uploadAttachments from "./uploadAttachments";
import setAmenityAdminFields from "./setAmenityAdminFields";
import setFieldType from "./setFieldType";
import city from "./city";
import additionalListingSubcats from "./additionalListingSubcats";
import saveDraftComm from "./saveDraftComm";
import sendTestComm from "./sendTestComm";
import sendComm from "./sendComm";
import addCustomEmail from "./addCustomEmail";
import cleanupDamFiles from "./cleanupDamFiles";
import setValueFromToken from "./setValueFromToken";
import countryToState from "./countryToState";
import browseFromAccountMedia from "./browseFromAccountMedia";
import updateFilesSelected from "./selectBrowseFiles";
import getContactEmail from "./getContactEmail";
import fetchAccountCommEmailOptions from "./fetchAccountCommEmailOptions";
import contactAccounts from "./contactAccounts";
import validateEmails from "./validateEmails";
import accountContactRel from "./accountContactRel";
import defaultAccountGroups from "./defaultAccountGroups";
import addTemplateHtml from "./addTemplateHtml";
import addSignatureHtml from "./addSignatureHtml";
import setPendingData from "./setPendingData";
import eventContactDetails from "./eventContactDetails";
import defaultTaskDescription from "./defaultTaskDescription";
import setModuleSettingFields from "./setModuleSettingFields";
import addContact from "./addContact";
import fetchConnectionOptions from "./fetchConnectionOptions";
import fetchConnectionGroupOptions from "./fetchConnectionGroupOptions";
import eventCallback from "./eventCallback";
import listingPrefill from "./listingPrefill";
import verifyCaptchaToken from "./validation/verifyCaptchaToken";
import fetchReferralListings from "./fetchReferralListings";
import updateReferralListings from "./updateReferralListings";
import listingCategories from "./listingCategories";
import sendReferralComm from "./sendReferralComm";
import visitorDynamicAction from "./actions/visitorDynamicAction";
import visitorSaveAndSend from "./submits/visitorSaveAndSend";
import commDedupEmails from "./commDedupEmails";
import accountOffers from "./accountOffers";
import accountEventCalendars from "./accountEventCalendars";
import followUpTask from "./followUpTask";
import setActionPropsWithValue from "./actions/setActionPropsWithValue";
import listingGeocode from "./listingGeocode";
import fetchGridField from "./fetchGridField";
import updateGridFieldList from "./updateGridFieldList";
import usersInGroups from "./usersInGroups";
import defaultFalse from "./defaultFalse";
import defaultDesc from "./defaultDesc";
import defaultToday from "./defaultToday";
import setSectionPropsWithValue from "./setSectionPropsWithValue";
import contactAddressPrefill from "./contactAddressPrefill";
import showDTNListing from "./showDTNListing";
import setPropSVUser from "./setPropSVUser";
import setCharacterCount from "./setCharacterCount";
import setImageLimit from "./setImageLimit";
import getNAICSCodes from "./getNAICSCodes";
import reverseGeocode from "./reverseGeocode";
import setAddressFieldsByListing from "./setAddressFieldsByListing";
import updateRelatedItems from "./updateRelatedItems";
import fetchRelatedItems from "./fetchRelatedItems";
import fetchDefaultWebForms from "./fetchDefaultWebForms";
import applyLinkedForm from "./applyLinkedForm";
import addRelatedAccount from "./addRelatedAccount";
import checkForPrimary from "./checkForPrimary";
import accountFromRef from "./accountFromRef";
import getAccountRelation from "./getAccountRelation";
import confirmDeactivation from "./confirmDeactivation";
import previewForm from "./previewForm";
import setPreviewData from "./setPreviewData";
import cancelFormBuilder from "./cancelFormBuilder";
import isLimitExceeded from "./isLimitExceeded";
import setDTNEnabled from "./setDTNEnabled";
import setReferralEnabled from "./setReferralEnabled";

const callbacks = {
	commDedupEmails,
	visitorSaveAndSend,
	birthMonth,
	geocode,
	city,
	createTag,
	listingContacts,
	listingAccountOverWrites,
	setPrimary,
	isUniqueEmail,
	eventCategory,
	accountListings,
	setDefaultVenueAccount,
	setDefaultVenueListing,
	getAssetIds,
	accountContacts,
	listingSubcategories,
	connectionModules,
	exampleCallbackFile,
	moduleSettingGroupFilter,
	submit,
	cancel,
	handleApproval,
	handleApprovalAndEdit,
	handleDenial,
	handleUpdateSettings,
	setAccountContactRelationFields,
	setFieldPropsWithValue,
	contactsFromAccount,
	optionLoader,
	fetchReferenceOptions,
	dynamicStringJoin,
	dynamicStringSplit,
	inquiryDetails,
	uploadAssets,
	uploadImages,
	uploadAttachments,
	setAmenityAdminFields,
	setFieldType,
	additionalListingSubcats,
	saveDraftComm,
	sendTestComm,
	sendComm,
	addCustomEmail,
	cleanupDamFiles,
	countryToState,
	browseFromAccountMedia,
	updateFilesSelected,
	setValueFromToken,
	getContactEmail,
	fetchAccountCommEmailOptions,
	referralDetails,
	contactAccounts,
	validateEmails,
	accountContactRel,
	defaultAccountGroups,
	addTemplateHtml,
	addSignatureHtml,
	setPendingData,
	eventContactDetails,
	defaultTaskDescription,
	setModuleSettingFields,
	addContact,
	fetchConnectionOptions,
	fetchConnectionGroupOptions,
	eventCallback,
	listingPrefill,
	verifyCaptchaToken,
	fetchReferralListings,
	updateReferralListings,
	listingCategories,
	sendReferralComm,
	visitorDynamicAction,
	accountOffers,
	accountEventCalendars,
	followUpTask,
	setActionPropsWithValue,
	listingGeocode,
	fetchGridField,
	updateGridFieldList,
	usersInGroups,
	defaultFalse,
	defaultDesc,
	defaultToday,
	setSectionPropsWithValue,
	contactAddressPrefill,
	showDTNListing,
	setPropSVUser,
	setCharacterCount,
	setImageLimit,
	getNAICSCodes,
	reverseGeocode,
	setAddressFieldsByListing,
	updateRelatedItems,
	fetchRelatedItems,
	fetchDefaultWebForms,
	applyLinkedForm,
	addRelatedAccount,
	checkForPrimary,
	accountFromRef,
	getAccountRelation,
	confirmDeactivation,
	previewForm,
	setPreviewData,
	cancelFormBuilder,
	isLimitExceeded,
	setDTNEnabled,
	setReferralEnabled
};

export default { ...callbacks };
